import React, { useMemo } from 'react';
import PangoBooksLogoAndName from '../assets/svg/icons/pangobooks';
import styles from '../styles/components/footer.module.scss';
import Link from 'next/link';

import YoutubeIconSVG from '../assets/svg/icons/youtube-icon';
import TwitterIconSVG from '../assets/svg/icons/twitter-icon';
import InstagramIconSVG from '../assets/svg/icons/instagram-icon';
import FacebookIconSVG from '../assets/svg/icons/facebook-icon';
import TikTokIconSVG from '../assets/svg/icons/tiktok-icon';
import AppStoreButtonSVG from '../assets/svg/app-store-button';
import GooglePlayButtonSVG from '../assets/svg/google-play-button';

import Links from '../lib/helpers/link-helper';

const Footer = () => {
  const { socialMedia } = Links;
  const { facebook, twitter, instagram, youtube, tiktok } = socialMedia;
  const year = useMemo(() => new Date().getFullYear(), []);

  const footerLinks = [
    {
      heading: 'Shop',
      links: [
        { href: Links.sellerSearch.index, label: 'Bookstores' },
        { href: Links.collections.index, label: 'Collections' },
        { href: Links.categories.index, label: 'Categories' },
        { href: Links.author.index, label: 'Authors' },
        { href: Links.giftCards.index, label: 'Gift cards' },
        { href: Links.shelves.index, label: 'Shelves' },
        { href: Links.series.index, label: 'Series' },
      ],
    },
    {
      heading: 'Sell',
      links: [
        { href: Links.sellersHub.index, label: 'Sellers Resource Hub' },
        { href: Links.ambassador.index, label: 'Ambassador' },
        { href: Links.marketGuide.index, label: 'Market Guide' },
      ],
    },
    {
      heading: 'Company',
      links: [
        { href: Links.about.index, label: 'About Us' },
        { href: Links.blog.index, label: 'Blog' },
        // { href: Links.career.index, label: 'Careers' },
        { href: Links.trustAndSafety.index, label: 'Trust and Safety' },
      ],
    },
    {
      heading: 'Help',
      links: [
        { href: Links.helpCenter.index, label: 'Help Center' },
        { href: Links.helpCenter.contactUs, label: 'Contact Us' },
        { href: Links.pangoBucks.index, label: 'PangoBucks FAQ' },
        {
          href: Links.featureRequests.index,
          label: 'Feature Requests',
        },
      ],
    },
  ];

  return (
    <footer className={styles.footer}>
      <div className={styles['footer-container']}>
        <div className={styles['footer-main-content']}>
          <div className={styles['first-col']}>
            <PangoBooksLogoAndName />
            <div className={styles['links-badges']}>
              <Link
                href={Links.appStore.apple}
                passHref
                rel="noopener noreferrer"
                aria-label="Download our app on the App Store"
                target="_blank"
              >
                <AppStoreButtonSVG />
              </Link>
              <Link
                href={Links.appStore.google}
                passHref
                rel="noopener noreferrer"
                aria-label="Download our app on Google Play"
                target="_blank"
              >
                <GooglePlayButtonSVG />
              </Link>
            </div>
          </div>
          <div className={styles['links']}>
            {footerLinks.map((group) => (
              <div key={group.heading}>
                <h3 className={styles['links-heading']}>{group.heading}</h3>
                <ul>
                  {group.links.map((link) => (
                    <li key={link.label}>
                      <Link
                        href={link.href}
                        passHref
                        className={`${styles['links-label']} `}
                      >
                        {link.label}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
        {/* Bottom content */}
        <div className={styles['footer-bottom']}>
          <ul className={styles['footer-legal']}>
            <li
              className={styles['footer-legal-copyright']}
            >{` © PangoBooks ${year}. All rights reserved.`}</li>
            <li>
              <Link href={Links.terms.index} passHref>
                Terms of Use
              </Link>
            </li>
            <li>
              <Link href={Links.privacy.index} passHref>
                Privacy Policy
              </Link>
            </li>
          </ul>

          <div className={styles['icons']}>
            <Link
              href={facebook}
              passHref
              rel="noopener noreferrer"
              aria-label="Visit our Facebook page"
              target="_blank"
            >
              <FacebookIconSVG />
            </Link>
            <Link
              href={twitter}
              passHref
              rel="noopener noreferrer"
              aria-label="Visit our Twitter page"
              target="_blank"
            >
              <TwitterIconSVG />
            </Link>
            <Link
              href={instagram}
              passHref
              rel="noopener noreferrer"
              aria-label="Visit our Instagram page"
              target="_blank"
            >
              <InstagramIconSVG />
            </Link>
            <Link
              href={youtube}
              passHref
              rel="noopener noreferrer"
              aria-label="Visit our Youtube page"
              target="_blank"
            >
              <YoutubeIconSVG />
            </Link>
            <Link
              href={tiktok}
              passHref
              rel="noopener noreferrer"
              aria-label="Visit our Tiktok page"
              target="_blank"
            >
              <TikTokIconSVG />
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
